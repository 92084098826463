<template>
    <painel titulo="Eventos do Cliente" icone="pi pi-users" :refreshFunction="atualizar">
        <erros-box :erros="erros"></erros-box>
        <tabela :data="eventos" dataKey="codEvento" :globalFilterFields="['codCliente', 'cliente', 'codEvento', 'evento']" stateKey="dt-crm-clientes-eventos">
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-download-certificado :evento="slotProps.data" @atualizarErros="erros = $event"></btn-download-certificado>
                    </template>
                </Column>
                <Column field="cliente" header="Cliente" :sortable="true">
                    <template #body="slotProps">
                        <span v-tooltip.bottom="`CodCliente: ${slotProps.data.codCliente}`"> {{ slotProps.data.cliente }}</span>
                    </template>
                </Column>
                <Column field="codEvento" header="Código Evento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codEvento }}
                    </template>
                </Column>
                <Column field="evento" header="Evento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.evento }}
                    </template>
                </Column>
                <Column field="dataInicio" header="Data Início" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataInicio, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
                <Column field="dataFim" header="Data Fim" :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataFim, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
                <Column field="aproveitamento" header="Aproveitamento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.aproveitamento }}
                    </template>
                </Column>
                <Column field="aprovado" header="Aprovado" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.aprovado === 1 ? 'SIM' : 'NÃO'"></status>
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
</template>

<script>
import Services from '../services';
import BtnDownloadCertificado from './BtnDownloadCertificado';

export default {
    data() {
        return {
            eventos: null,
            erros: [],
        };
    },

    methods: {
        obterEventos() {
            this.$store.dispatch('addRequest');

            let filtro = {};

            if (this.$route.query.codParceiro && !this.$route.query.codparceiro) {
                filtro.codParceiro = this.$route.query.codParceiro;
            } else if (!this.$route.query.codParceiro && this.$route.query.codparceiro) {
                filtro.codParceiro = this.$route.query.codparceiro;
            }

            Services.obterEventos(filtro).then((response) => {
                if (response?.success) {
                    this.eventos = response.data;
                } else {
                    this.eventos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterEventos();
        },
    },

    created() {
        this.$store.dispatch('trocarMenu');
        this.obterEventos();
    },

    watch: {
        '$route.query'() {
            this.obterEventos();
        },
    },

    components: {
        BtnDownloadCertificado,
    },
};
</script>