import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CRM_PORT}${process.env.VUE_APP_API_PATH}/crm/clientes`;

export default {
    obterHistorico(filtro) {
        return axiosJwt.get(
            `${api}/historico?codsebrae=${filtro.codSebrae}&codparceiro=${filtro.codParceiro}`
        );
    },

    obterEventos(filtro) {
        return axiosJwt.get(
            `${api}/eventos?codparceiro=${filtro.codParceiro}`
        );
    },

    downloadCertificado(filtro) {
        return axiosJwt.get(
            `${api}/eventos/certificados?codparceiro=${filtro.codCliente
            }&codevento=${filtro.codEvento
            }&datainicio=${filtro.dataInicio
            }&datafim=${filtro.dataFim}`, {
            responseType: 'blob',
        }
        );
    }
};
