<template>
    <span class="p-text-primary"><i class="pi pi-download icon-button ml-2" @click="download" title="Download Certificado"></i></span>
</template>

<script>
import Services from '../services';

export default {
    emits: ['atualizarErros'],

    props: {
        evento: {
            type: Object,
        },
    },

    data() {
        return {};
    },

    methods: {
        download() {
            this.$store.dispatch('addRequest');
            this.$emit('atualizarErros', []);
            let filtroDto = {
                codCliente: this.evento.codCliente,
                codEvento: this.evento.codEvento,
                dataInicio: this.evento.dataInicio ? this.$moment(this.evento.dataInicio).format('YYYY-MM-DD') : '',
                dataFim: this.evento.dataFim ? this.$moment(this.evento.dataFim).format('YYYY-MM-DD') : '',
            };
            Services.downloadCertificado(filtroDto).then((response) => {
                if (response?.success) {
                    this.$download(response.data, `CertificadoEvento_${this.evento.codEvento}.pdf`);
                } else {
                    this.$emit('atualizarErros', response.errors);
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>